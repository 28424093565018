import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Button, TextField, makeStyles, withStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward, Search } from '@material-ui/icons';
import { BeatLoader } from 'react-spinners';
import qrCode from '../images/enrollment_qr.png';
import paso1 from '../images/paso1.png';
import paso1dot2 from '../images/paso1.2.png';
import paso2 from '../images/paso2.png';
import paso3 from '../images/paso3.png';
import paso4 from '../images/paso4.png';
import paso5 from '../images/paso5.png';
import paso6 from '../images/paso6.png';
import paso7 from '../images/paso7.png';
import paso8 from '../images/paso8.png';
import paso13 from '../images/paso13.png';
import paso16 from '../images/paso16.png';
import homeScreen from '../images/kreceScreen.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';

// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const Warranty = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [storeName, setStoreName] = useState('');
    const [securityProvider, setSecurityProvider] = useState('');
    //User creation data
    const [cedula, setCedula] = useState('');
    const [formError, setFormError] = useState('');
    const [storeUser, setStoreUser] = useState('');
    const [isGuarantee, setIsGuarantee] = useState(false);
    const [storeEmail, setStoreEmail] = useState('');

    // device registration data
    const [loginError, setLoginError] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
    const [deviceID, setDeviceID] = useState('');
    const [imeiNumber, setImeiNumber] = useState('');
    const [confNumber, setConfNumber] = useState('');
    const [imeiToRegister, setImeiToRegister] = useState('');
    const [imeiToRegister2, setImeiToRegister2] = useState('');
    const [appToken, setAppToken] = useState('');
    const [tokenGenerated, setTokenGenerated] = useState(false);

    //webapp data
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [loadingText, setLoadingText] = useState(false);
    const [loadingSubText, setLoadingSubText] = useState(false);
    const [showImei, setShowImei] = useState(false);
    const [showConfNumber, setShowConfNumber] = useState(false);
    const [page, setPage] = useState(2);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [switchChecked2, setSwitchChecked2] = useState(false);
    const [blockNuovo, setBlockNuovo] = useState(false);
    const [isOnline, setIsOnline] = useState(false);

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        setIsLoading(true);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                setStoreUser(user.email)
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        setStoreEmail(masterStoreRef.id)
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country)
                                const storeName = data.store_name;
                                setBlockNuovo(data.only_allow_tonic === true ? true : false);
                                setSecurityProvider(data.security_provider);
                                setStoreName(storeName);
                                setIsLoading(false);
                            });
                        setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': storeEmail,
                    'x-user-email': storeUser
                },
                body: JSON.stringify({ ...data, country: country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)
                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };


    function convertDateFormat(dateString) {
        // Split the input string into day, month, and year components
        const [day, month, year] = dateString.split('/');

        // Create a new Date object with the parsed components
        const parsedDate = new Date(`${year}-${month}-${day}`);

        // Extract the year, month, and day components in yyyy-MM-dd format
        const formattedDate = parsedDate.toISOString().split('T')[0];

        return formattedDate;
    }

    function getUserInput() {
        function getInputRecursive() {
            const userInput = window.prompt('No pudimos identificar la cédula por favor introduzca el número aquí:');
            if (userInput === null) {
                // User clicked cancel or closed the prompt
                return getInputRecursive(); // Ask again
            } else if (!/^[0-9]+$/.test(userInput)) {
                // Invalid input (doesn't match the regex)
                window.alert('Por favor introduzca un número de cédula válido.');
                return getInputRecursive(); // Ask again
            } else {
                // Valid input
                return userInput;
            }
        }

        return getInputRecursive();
    }

    const sendAppToken = async (e) => {
        setFormError('')
        setIsLoading(true);
        const data = {
            identification_number: cedula,
            code_type: 'in_app'
        }
        const resp2 = await sendPostRequest(data, 'notifications/requestauthcode/', token); //to-do: Necesito mandar la cedula? ese token como se va a guardar en la base de datos?
        if (resp2?.success) {
            alert('Token generado en app exitosamente.')
            setTokenGenerated(true);
            setIsLoading(false);
        } else {
            setFormError("Ocurrió un problema generando el token de confirmación del app, por favor vuelva a intentar.");
            setIsLoading(false);
        }
    }

    const handleSendDeviceID = async (e) => {
        if (isGuarantee) {
            await handleDeviceIdGuaranteeSubmit();
            return;
        }
    }

    const goToPage = (x) => {
        setFormError('')
        setLoginError('')
        setPage(x);
    }

    const backToWelcomePage = () => {
        window.location.reload();
    };

    const searchConfirmationNumber = async () => {
        setConfNumber('');
        setShowConfNumber(false)
        setLoginError('')
        const data = {
            store_name: storeName,
            imei: imeiNumber
        }
        const response = await sendPostRequest(data, 'registration/finddevice/', token)
        if (response?.success) {
            setConfNumber(response.data.nuovo_id);
            setShowConfNumber(true)
        } else if (response?.success === false) {
            setLoginError(response.status);
            setIsLoading(false);
        } else {
            setLoginError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const checkDeviceReady = async () => {
        let breakLoop = false
        let status = 'waiting'

        for (let i = 0; i < 10; i++) {
            if (breakLoop) {
                break;
            }
            const devDat = {
                device_imei: imeiToRegister
            };
            await new Promise((resolve) => {
                setTimeout(async () => {
                    try {
                        const resp = await sendPostRequest(devDat, 'registration/imeistatus/', token);

                        if (resp?.success) {
                            if (resp.device_ready) {
                                setIsLoading(false);
                                setLoadingText('');
                                setLoadingSubText('');
                                setPage(18);
                                breakLoop = true
                                status = 'success'
                            }
                        } else if (resp?.success === false) {
                            if (resp?.invalid_device) {
                                // setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
                                if (switchChecked) {
                                    setFormError("Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, si el problema persiste contacte a soporte técnico.");
                                    setIsLoading(false);
                                    setLoadingText('');
                                    setLoadingSubText('');
                                    return;
                                } else if (blockNuovo) {
                                    setFormError("Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, Infinix, Tecno, Realme, Itel, o Motorola, si el problema persiste contacte a soporte técnico.");
                                    setIsLoading(false);
                                    setLoadingText('');
                                    setLoadingSubText('');
                                    return;
                                }
                                setSecurityProvider("nuovo");
                                setIsLoading(false);
                                setLoadingText('');
                                setLoadingSubText('');
                                return;
                            } else {
                                setFormError(resp.status);
                            }
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true
                            status = 'fail'
                        } else {
                            setFormError("Estamos teniendo problemas para conectarnos.");
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true
                            status = 'fail'
                        }
                    } catch (error) {
                        console.error('Error occurred:', error);
                        setFormError("Error al realizar la solicitud.");
                        setIsLoading(false);
                        setLoadingText('');
                        setLoadingSubText('');
                        breakLoop = true
                        status = 'fail'
                    }
                    resolve();
                }, 15000);
            });
        }
        if (status === "waiting") {
            setFormError("Se ha alcanzado el tiempo máximo de espera, por favor vuelva a intentar o contacte a servicio técnico.");
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
        }
    };

    const registerWithTonic = async () => {
        setFormError('')
        if (!imeiToRegister || !imeiToRegister2) {
            setFormError('Por favor rellene todas las casillas para continuar.')
            return;
        }
        if (imeiToRegister.length < 15 || imeiToRegister2.length < 15) {
            setFormError('IMEI ivalido, asegurese de que el IMEI tenga 15 caracteres.')
            return;
        }
        if (imeiToRegister !== imeiToRegister2) {
            setFormError('Los IMEIs introducidos no coinciden.')
            return;
        }
        try {
            setIsLoading(true);
            setLoadingText('Registrando equipo');
            setLoadingSubText('Esto puede tardar unos segundos, por favor NO encienda el teléfono aún.');
            const devData = {
                device_imei: imeiToRegister
            }
            let response
            if (switchChecked2) {
                response = await sendPostRequest(devData, 'registration/promo2/', token)
            } else {
                response = await sendPostRequest(devData, 'registration/registerimei/', token)
            }

            if (response?.success) {
                await checkDeviceReady();
            } else if (response?.success === false) {
                setFormError(response.status)
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
            } else {
                setFormError("Estamos teniendo problemas para conectarnos.")
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
            }
        } catch (err) {
            setFormError("Error: " + err.message);
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
        }
    }

    const callDevReg = async () => {
        try {
            setIsLoading(true);
            setLoadingText('Finalizando registro');
            if (isGuarantee) {
                await handleDeviceIdGuaranteeSubmit();
                return;
            }
        } catch (err) {
            setFormError("Error: " + err.message);
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
        }
    }

    const handleDeviceIdGuaranteeSubmit = async () => {
        if (securityProvider !== "tonic" && !deviceID) {
            await setFormError('Por favor complete todas las casillas.');
            return;
        }
        if (!appToken) {
            setFormError('Por favor introduzca el token.');
            return;
        }

        setFormError('');
        setIsLoading(true);
        const usersCollection = firestore.collection('Users');
        const query = usersCollection
            .where('identification_number', '==', cedula)
            .limit(1);
        await query.get().then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                // to-do mandar app_token
                let deviceData = {
                    device_id: deviceID,
                    user_document_id: doc.id,
                    device_management_provider: "nuovo",
                    app_token: appToken,
                    store_user: storeUser
                }
                if (securityProvider === "tonic") { //to-do mandar app_token
                    deviceData = {
                        device_imei: imeiToRegister,
                        user_document_id: doc.id,
                        device_management_provider: "tonic",
                        app_token: appToken,
                        store_user: storeUser
                    }
                }
                const response = await sendPostRequest(deviceData, 'registration/replacedevice/', token) //status, success, data
                if (response?.success) {
                    setPage(15)
                    setIsLoading(false);
                    setLoadingText('');
                    setLoadingSubText('');
                } else if (response?.success === false) {
                    setFormError(response.status);
                    setIsLoading(false);
                    setLoadingText('');
                    setLoadingSubText('');
                } else {
                    setFormError('Estamos teniendo problemas para conectarnos. ');
                    setIsLoading(false);
                    setLoadingText('');
                    setLoadingSubText('');
                }
            });
        }).catch((error) => {
            console.log(error.message)
            setFormError('Estamos teniendo problemas para conectarnos. ')
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
            return
        })
    }

    const checkTonicStatus = async () => {
        setFormError('');
        let breakLoop = false;
        let status = 'waiting';
        setIsLoading(true);
        setLoadingText('Chequeando estatus del equipo');
        setLoadingSubText('Esto puede tardar unos minutos.');
        for (let i = 0; i < 10; i++) {
            if (breakLoop) {
                break;
            }
            const devDat = {
                device_imei: imeiToRegister
            };
            await new Promise((resolve) => {
                setTimeout(async () => {
                    try {
                        const resp = await sendPostRequest(devDat, 'registration/activeimei/', token);
                        if (resp?.success) {
                            if (resp.device_active) {
                                setIsLoading(false);
                                setLoadingText('');
                                setLoadingSubText('');
                                setPage(19);
                                status = "success";
                                breakLoop = true;
                            }
                        } else if (resp?.success === false) {
                            if (resp?.invalid_device) {
                                setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
                            } else {
                                setFormError(resp.status);
                            }
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true;
                            status = "fail";
                        } else {
                            setFormError("Estamos teniendo problemas para conectarnos.");
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true;
                            status = "fail";
                        }
                    } catch (error) {
                        console.error('Error occurred:', error);
                        setFormError("Error al realizar la solicitud.");
                        setIsLoading(false);
                        setLoadingText('');
                        setLoadingSubText('');
                        breakLoop = true;
                        status = "fail";
                    }
                    resolve();
                }, 15000);
            });
        }
        if (status === "waiting") {
            // setFormError("Error: El celular no fue encendido o configurado correctamente. Si cree que es un error vuelva a hacer clic en Siguiente, de lo contrario debe formatear el equipo de fabrica, prenderlo, configurarlo, y luego hacer clic en Siguiente.");
            setSecurityProvider('nuovo');
            setPage(10);
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
            alert('El equipo no se pudo configurar correctamente. Porfavor reseteelo de fabrica y prosiga con la instalación con QR.')
        }
    }

    const handleContinueGuarantee = async () => {
        setIsLoading(true);
        // Simulating API call delay
        try {
            const cedulaData = {
                identification_number: cedula
            }
            const response = await sendPostRequest(cedulaData, 'user_check_warranty/', token) //status, success, data
            if (response?.success) {
                if (response.data.is_user_registered && response.data.is_elegible_for_warranty) {
                    //se manda a un screen para que introduzca el nuevo IMEI y despues se manda un API call a replace_old_device
                    setPage(9)
                    setIsLoading(false);
                } else if (!response.data.is_user_registered) {
                    await setLoginError('El cliente no existe.');
                    setIsLoading(false);
                } else if (response.data.is_user_registered && !response.data.is_elegible_for_warranty) {
                    await setLoginError('Este cliente no tiene deudas con Krece. No tiene que reportar el cambio de teléfono.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setLoginError(response.status);
                setIsLoading(false);
            } else {
                setLoginError('Estamos teniendo problemas para conectarnos. ');
                setIsLoading(false);
            }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos. ');
            setIsLoading(false);
        }
    }

    const handleConfigureGuarantee = (x) => {
        setIsGuarantee(true);
        goToPage(7);
    }

    return (
        <div>
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>
                {isLoading && (
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                {loadingText}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                            <BeatLoader color="#45C4D6" />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                            <Typography variant="h6" className={classes.title2} gutterBottom>
                                {loadingSubText}
                            </Typography>
                        </div>
                    </div>
                )}

                {page === 2 && !isLoading && (
                    <div className={classes.containerWelcome}>
                        <div className={classes.topButton}>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                {"Cambio por garantía"}
                            </Typography>
                            <p className={classes.approvalMsg}>Por favor ingresa la cédula del cliente para realizar el cambio por garantía</p>
                            <> <TextField
                                label="Cédula del aplicante"
                                name="cedula"
                                value={cedula}
                                onChange={(e) => setCedula(e.target.value)}
                                // variant="outlined"
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                error={loginError !== ''}
                                helperText={loginError}
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                                <Button variant="contained" size="small" margin="normal" className={classes.welcomeButtons} onClick={handleContinueGuarantee}>
                                    Continuar
                                </Button> </>
                        </div>
                    </div>
                )}
                {page === 9 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Celular con garantía
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                            Por favor realice la configuración del equipo que va a reemplazar al original.
                        </Typography>
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleConfigureGuarantee}>
                            Siguiente
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            className={classes.welcomeButtons}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 7 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Configuración de seguridad
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                            Ahora, el encargado(a) en la tienda va a configurar nuestro app de seguridad en el dispositivo, lo cual le dará permiso a Krece para bloquear y desbloquear el dispositivo en caso de que se atrase con sus pagos.
                        </Typography>
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(10)}>
                            Comenzar Configuración
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 10 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        {securityProvider === "tonic" ?
                            <>
                                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                    Configuración de seguridad
                                </Typography>
                                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                    Para comenzar, asegurese que el equipo se encuentre apagado.
                                </Typography>
                                <TextField
                                    label="IMEI del equipo"
                                    name="imeiToRegister"
                                    value={imeiToRegister}
                                    type="password"
                                    onChange={(e) => setImeiToRegister(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                <TextField
                                    label="Confirme el IMEI del equipo"
                                    name="imeiToRegister2"
                                    value={imeiToRegister2}
                                    type="password"
                                    onPaste={(e) => e.preventDefault()}
                                    onChange={(e) => setImeiToRegister2(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                {formError && <p className={classes.formError}>{formError}</p>}
                                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={registerWithTonic}>
                                    Siguiente
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.welcomeButtons}
                                    startIcon={<ArrowBack />}
                                    onClick={() => setPage(7)}
                                    style={{ marginTop: '10px' }}
                                >
                                    Atrás
                                </Button>
                            </> :
                            <>
                                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                    Configuración de seguridad
                                </Typography>
                                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                    1. Encienda el dispositivo nuevo y en la primera pantalla cambie el idioma a Español. En esa misma pantalla, toque en cualquier lugar de la pantalla 6 veces para ver un escáner de código QR.
                                </Typography>
                                <img style={{ width: 150, marginRight: 50 }} src={paso1} alt="Paso 1" />
                                <img style={{ width: 150, marginRight: 50 }} src={paso1dot2} alt="Paso 1.2" />
                                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                    2. Escanee el siguiente código QR para iniciar la instalación del app de seguridad.
                                </Typography>
                                <img style={{ width: 200 }} src={qrCode} alt="Enrollment QR" />
                                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(11)}>
                                    Seguir
                                </Button>
                                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                                    <Button
                                        variant="contained"
                                        className={classes.welcomeButtons}
                                        startIcon={<ArrowBack />}
                                        onClick={() => goToPage(7)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Atrás
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.welcomeButtons}
                                        endIcon={<ArrowForward />}
                                        onClick={() => goToPage(14)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Saltar Instrucciones
                                    </Button>
                                </div></>}
                    </div>
                )}
                {
                    page === 18 && !isLoading && (
                        <>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                Configuración de seguridad
                            </Typography>
                            <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                                Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y realizar la configuración hasta que vea la siguiente pantalla.
                            </Typography>
                            <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                                {'IMPORTANTE (NO APLICA PARA SAMSUNG): SI NO SE LOGRA LLEGAR A LA PANTALLA QUE MOSTRAMOS ABAJO, TERMINE DE CONFIGURAR EL CELULAR E INTENTE RESTAURAR DE FABRICA. SI EL EQUIPO LE PERMITE RESTAURAR DE FABRICA QUIERE DECIR QUE NO SE CONFIGURO CORRECTAMENTE Y SE DEBE RESTAURAR DE FABRICA Y VOLVER A COMENZAR. DE LO CONTRARIO, SI SE CONFIGURO CORRECTAMENTE Y PUEDE HACER CLIC EN SIGUIENTE.'}
                            </Typography>
                            <img style={{ height: 448, width: 200, marginBottom: 10, marginTop: 10, alignSelf: 'center' }} src={homeScreen} alt="homeScreen" />
                            {formError && <p className={classes.formError}>{formError}</p>}
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={checkTonicStatus}>
                                Siguiente
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<ArrowBack />}
                                onClick={() => setPage(10)}
                                className={classes.welcomeButtons}
                            >
                                Atrás
                            </Button>
                        </>
                    )
                }
                {
                    page === 19 && !isLoading && (
                        <>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                Código de App Krece
                            </Typography>
                            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                {'Ahora proceda a descargar la app Krece desde internet o Play Store en el equipo en venta. (Este paso NO es necesario para equipos SAMSUNG)'}
                            </Typography>
                            {!isOnline && <>
                                <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                                    Ahora haga clic en "Generar Token", abra la app Krece, cree una cuenta, y obtenga el token que ahí se encuentra.
                                </Typography>
                                {!tokenGenerated ? <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                    Generar Token
                                </Button> : <><TextField
                                    label="Token"
                                    name="appToken"
                                    value={appToken}
                                    onChange={(e) => setAppToken(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                    <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                        Generar Otro Token
                                    </Button></>}
                            </>}
                            {formError && <p className={classes.formError}>{formError}</p>}
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={callDevReg}>
                                Siguiente
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<ArrowBack />}
                                onClick={() => setPage(18)}
                                className={classes.welcomeButtons}
                            >
                                Atrás
                            </Button>
                        </>
                    )
                }
                {page === 11 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            3. Haga clic en aceptar cuando se le solicite.
                        </Typography>
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso2} alt="Paso2" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso3} alt="Paso3" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso4} alt="Paso4" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso5} alt="Paso5" />
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(12)}>
                            Seguir
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(10)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 12 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            4. Haga clic en "Ajustes adicionales" y después en "PERMITR".
                        </Typography>
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso6} alt="Paso6" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso7} alt="Paso7" />
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(13)}>
                            Seguir
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(11)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 13 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            5. Siga las inctrucciones que se le muestran en la pantalla y cuando esté listo haga clic en "Configuración Completa"
                        </Typography>
                        <img style={{ width: 200, marginRight: 30, marginBottom: 10 }} src={paso8} alt="Paso8" />
                        <img style={{ width: 200, marginBottom: 10 }} src={paso13} alt="Paso13" />
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(14)}>
                            Seguir
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(12)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 14 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            6. Por favor introduzca el código de confirmación de 7 digitos en la siguiente casilla.
                        </Typography>
                        <TextField
                            label="Código de confirmación"
                            name="deviceID"
                            value={deviceID}
                            onChange={(e) => setDeviceID(e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                        <Button variant="text" className={classes.welcomeButtonsNoBorder} fullWidth onClick={() => setShowImei(true)} gutterBottom>
                            ¿No encuentra el código de confirmación?
                        </Button>
                        {showImei && <div className={classes.border}><TextField
                            label="Número IMEI del equipo"
                            name="imeiNumber"
                            value={imeiNumber}
                            onChange={(e) => setImeiNumber(e.target.value)}
                            error={loginError !== ''}
                            helperText={loginError}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                startIcon={<Search />}
                                onClick={() => searchConfirmationNumber()}
                                style={{ marginTop: '10px' }}
                            >
                                Buscar
                            </Button>
                            {showConfNumber && <p style={{ color: 'white' }}>{"El código de confirmación de este equipo es: " + confNumber}</p>}
                        </div>}
                        <img style={{ width: 200, marginBottom: 10, marginTop: 30 }} src={paso16} alt="Paso16" />
                        {!isOnline && <> <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            7. Para finalizar, haga clic en "Generar Token", luego abra la app Krece, cree una cuenta, y obtenga el token que ahí se encuentra.
                        </Typography>

                            {!tokenGenerated ? <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                Generar Token
                            </Button> : <><TextField
                                label="Token"
                                name="appToken"
                                value={appToken}
                                onChange={(e) => setAppToken(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                                <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                    Generar Otro Token
                                </Button></>}
                        </>}
                        {formError && <p className={classes.formError}>{formError}</p>}
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleSendDeviceID}>
                            Enviar
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(13)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 15 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            ¡Cambio por garantía exitoso!
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            IMPORTANTE: Antes de que el cliente se lleve su celular asegurese que le llegó un email de confirmación a la tienda, eso se utilizará como comprobante en caso de cualquier problema que ocurra.
                        </Typography>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            style={{ marginTop: '10px' }}
                        >
                            Volver al inicio
                        </Button>
                    </div>
                )}
            </Container>
        </div >
    );
};

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        textAlign: 'center', color: "silver", fontSize: 16
    },
    switch_track: {
        backgroundColor: "gray",
    },
    switch_base: {
        color: "gray",
        "&.Mui-disabled": {
            color: "gray"
        },
        "&.Mui-checked": {
            color: "gray"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "gray",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#65C466",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#65C466",
        },
    },
    totalAmt: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    textFields2: {
        color: 'white',
    },
    textLabel: {
        color: 'white'
    },
    textInput: {
        backgroundColor: '#2A2E32',
        color: 'white',
        border: "1px solid #ccc",
        borderRadius: '8px',
        padding: 8,
        fontSize: 16,
        width: 100
    },
    paymentItem: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 10,
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        // height: 700,
    },
    topButton: {
        flex: 1
    },
    formError: {
        color: "#e84e4f"
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    title2: {
        color: 'white',
        fontSize: 16,
        marginBottom: 10
    },
    highlighted: {
        color: '#45C4D6',
    },
    appBarButton: {
        width: 200
    },
    border: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        padding: "10px",
        marginBottom: 20,
    },
    border2: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingBottom: 5,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#1C1C1E',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
}));

export default Warranty;
